import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Formik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { breakpoints, Button, Cards, FormField, Responsive, Skeleton, Text, useMediaQuery } from '@components';
import {
  columnDirection,
  columnSpace,
  Dictionaries,
  FormType,
  InputType,
  Translation,
  WAFActionType,
  WAFApplicationLanguage,
  WAFApplicationType,
  WAFSensitivity,
} from '@enums';
import { useAppDispatch, useErrorFormInner } from '@hooks';
import { submitFormRequest, useFormContent, useFormLoadingStatus, useFormSubmissionStatus } from '@store';
import { getOptionListFromCatalog } from '@utils';

type rulesDescription = {
  name: string;
  description: string;
};

interface PredefinedFormProps {
  application_type: WAFApplicationType;
  action: WAFActionType;
  sensitivity: WAFSensitivity;
  status: boolean;
  cross_request_mode: boolean;
  rulesDescription: rulesDescription[];
  application_language: WAFApplicationLanguage;
}

export const PredefinedWAFRules = ({ onFormikInstance, onSaveClose }) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnWAF;

  const { t: tWAFForm } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.waf.predefined' });
  const { t: tWAFDescription } = useTranslation(Translation.Configuration, {
    keyPrefix: 'tabs.waf.predefined.description',
  });
  const { t: tDescription } = useTranslation(Translation.Description, {
    keyPrefix: 'configuration.waf.predefined.rules',
  });

  const data = useFormContent(formType);
  const isDataLoading = useFormLoadingStatus({ formType });
  const isSubmitting = useFormSubmissionStatus({ formType });

  const handleSubmit = useCallback(
    (values: any) => {
      const { rulesDescription, ...updatedValues } = values;
      dispatch(
        submitFormRequest({
          formType,
          formData: { application_id, ...updatedValues },
          globalOptions: {
            returnResult: true,
          },
        }),
      );
    },
    [application_id, dispatch, onSaveClose],
  );

  const PredefinedWAFInner = () => {
    useErrorFormInner({ formType, isSubmitting });
    return null;
  };

  const initialValues: PredefinedFormProps = {
    application_type: data?.application_type ?? WAFApplicationType.Blog,
    action: data?.action ?? WAFActionType.Log,
    sensitivity: data?.sensitivity ?? WAFSensitivity.High,
    status: data?.status ?? false,
    cross_request_mode: data?.cross_request_mode ?? true,
    application_language: data?.application_language ?? WAFApplicationLanguage.PHP,
    rulesDescription: [
      {
        name: tDescription('captcha'),
        description: tDescription('captchaDescription'),
      },
      {
        name: tDescription('js-challenge'),
        description: tDescription('js-challengeDescription'),
      },
      {
        name: tDescription('log'),
        description: tDescription('logDescription'),
      },
      {
        name: tDescription('close-connection'),
        description: tDescription('close-connectionDescription'),
      },
    ],
  };

  const defaultFormFieldProps = {
    smallFont: true,
    fullWidth: true,
    direction: columnDirection.ColumnRow,
    columnPercentage: columnSpace.Half,
    className: 'gap-5 items-center flex pt-8 ',
  };

  const isAboveSm = useMediaQuery(`(min-width: ${breakpoints.sm}px)`);

  if (isDataLoading)
    return (
      <Skeleton className="flex rounded-2xl justify-center items-center overflow-hidden px-0 sm:px-8 py-7 h-full" />
    );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => {
        if (onFormikInstance) onFormikInstance(formik);

        return (
          <Cards rounded="rounded-2xl" withoutPadding className="flex flex-col justify-between overflow-hidden">
            <PredefinedWAFInner />
            <div className="flex flex-col sm:space-y-3 py-7">
              <FormField
                name="predefinedWAF"
                direction={columnDirection.Column}
                label={
                  <Text $customizeColor $level={3} className="font-medium text-theme-primary-main px-8">
                    {tWAFForm('predefinedWafRules')}
                  </Text>
                }
                className="gap-5"
                inputType={InputType.Custom}
              >
                <div className="gap-8 flex flex-col sm:divide-y-0 divide-y px-8">
                  <FormField
                    {...defaultFormFieldProps}
                    label={
                      <div className="flex gap-3 items-center">
                        <Text color="text-black" $level={4} className={`text-left font-normal`}>
                          {`${tWAFForm('enabled')}`}
                        </Text>
                        <Responsive showBelow="sm">
                          <Button
                            icon={<InformationCircleIcon className="size-5 text-theme-primary-main" />}
                            hint={
                              <div className="bg-black -mx-2.5 -my-1.5 rounded-lg shadow-card px-2.5 py-1.5">
                                <Text
                                  color="text-white"
                                  $level={5}
                                  className="text-justify leading-5"
                                >{`${tWAFDescription('enabled')}`}</Text>
                              </div>
                            }
                            withoutPadding
                            withoutScale
                            customizeColor
                          />
                        </Responsive>
                      </div>
                    }
                    name="status"
                    besideTitle={!isAboveSm}
                    inputType={InputType.Switch}
                    description={
                      <Responsive showAbove="sm">
                        <Text $level={5} className={`text-left font-normal leading-5`}>
                          {`${tWAFDescription('enabled')}`}
                        </Text>
                      </Responsive>
                    }
                  />

                  <FormField
                    {...defaultFormFieldProps}
                    label={
                      <div className="flex gap-3 items-center  pb-4 sm:pb-0">
                        <Text color="text-black" $level={4} className={`text-left font-normal`}>
                          {`${tWAFForm('type')}`}
                        </Text>
                        <Responsive showBelow="sm">
                          <Button
                            icon={<InformationCircleIcon className="size-5 text-theme-primary-main" />}
                            hint={
                              <div className="bg-black -mx-2.5 -my-1.5 rounded-lg shadow-card px-2.5 py-1.5">
                                <Text
                                  color="text-white"
                                  $level={5}
                                  className="text-justify leading-5"
                                >{`${tWAFDescription('applicationType')}`}</Text>
                              </div>
                            }
                            withoutPadding
                            withoutScale
                            customizeColor
                          />
                        </Responsive>
                      </div>
                    }
                    name="application_type"
                    inputType={InputType.Select}
                    disabled={!formik.values?.status || isSubmitting}
                    placeholder={tWAFForm('selectYourApplicationType')}
                    options={getOptionListFromCatalog(Dictionaries.WAFType)}
                    description={
                      <Responsive showAbove="sm">
                        <Text $level={5} className={`text-left font-normal leading-5`}>
                          {`${tWAFDescription('applicationType')}`}
                        </Text>
                      </Responsive>
                    }
                  />
                  <FormField
                    {...defaultFormFieldProps}
                    label={
                      <div className="flex gap-3 items-center  pb-4 sm:pb-0">
                        <Text color="text-black" $level={4} className={`text-left font-normal`}>
                          {`${tWAFForm('applicationLanguage')}`}
                        </Text>
                        <Responsive showBelow="sm">
                          <Button
                            icon={<InformationCircleIcon className="size-5 text-theme-primary-main" />}
                            hint={
                              <div className="bg-black -mx-2.5 -my-1.5 rounded-lg shadow-card px-2.5 py-1.5">
                                <Text
                                  color="text-white"
                                  $level={5}
                                  className="text-justify leading-5"
                                >{`${tWAFDescription('applicationLanguage')}`}</Text>
                              </div>
                            }
                            withoutPadding
                            withoutScale
                            customizeColor
                          />
                        </Responsive>
                      </div>
                    }
                    name="application_language"
                    inputType={InputType.Select}
                    disabled={!formik.values?.status || isSubmitting}
                    placeholder={tWAFForm('selectYourSensitivity')}
                    options={getOptionListFromCatalog(Dictionaries.WAFLanguage)}
                    description={
                      <Responsive showAbove="sm">
                        <Text $level={5} className={`text-left font-normal leading-5`}>
                          {`${tWAFDescription('applicationLanguage')}`}
                        </Text>
                      </Responsive>
                    }
                  />
                  <FormField
                    {...defaultFormFieldProps}
                    label={
                      <div className="flex gap-3 items-center pb-4 sm:pb-0">
                        <Text color="text-black" $level={4} className={`text-left font-normal`}>
                          {`${tWAFForm('sensitivityLevel')}`}
                        </Text>
                        <Responsive showBelow="sm">
                          <Button
                            icon={<InformationCircleIcon className="size-5 text-theme-primary-main" />}
                            hint={
                              <div className="bg-black -mx-2.5 -my-1.5 rounded-lg shadow-card px-2.5 py-1.5">
                                <Text
                                  color="text-white"
                                  $level={5}
                                  className="text-justify leading-5"
                                >{`${tWAFDescription('sensitivityLevel')}`}</Text>
                              </div>
                            }
                            withoutPadding
                            withoutScale
                            customizeColor
                          />
                        </Responsive>
                      </div>
                    }
                    name="sensitivity"
                    inputType={InputType.Select}
                    disabled={!formik.values?.status || isSubmitting}
                    placeholder={tWAFForm('selectYourSensitivity')}
                    options={getOptionListFromCatalog(Dictionaries.WAFSensitivity)}
                    description={
                      <Responsive showAbove="sm">
                        <Text $level={5} className={`text-left font-normal leading-5`}>
                          {`${tWAFDescription('sensitivityLevel')}`}
                        </Text>
                      </Responsive>
                    }
                  />
                  <FormField
                    {...defaultFormFieldProps}
                    label={
                      <div className="flex gap-3 items-center pb-4 sm:pb-0">
                        <Text color="text-black" $level={4} className={`text-left font-normal`}>
                          {`${tWAFForm('blockAction')}`}
                        </Text>
                        <Responsive showBelow="sm">
                          <Button
                            icon={<InformationCircleIcon className="size-5 text-theme-primary-main" />}
                            hint={
                              <div className="bg-black -mx-2.5 -my-1.5 rounded-lg shadow-card px-2.5 py-1.5">
                                <Text
                                  color="text-white"
                                  $level={5}
                                  className="text-justify leading-5"
                                >{`${tWAFDescription('blockAction')}`}</Text>
                              </div>
                            }
                            withoutPadding
                            withoutScale
                            customizeColor
                          />
                        </Responsive>
                      </div>
                    }
                    name="action"
                    inputType={InputType.Select}
                    disabled={!formik.values?.status || isSubmitting}
                    placeholder={tWAFForm('selectYourBlockAction')}
                    options={getOptionListFromCatalog(Dictionaries.WAFAction)}
                    description={
                      <Responsive showAbove="sm">
                        <Text $level={5} className={`text-left font-normal leading-5`}>
                          {`${tWAFDescription('blockAction')}`}
                        </Text>
                      </Responsive>
                    }
                  />
                  <FormField
                    {...defaultFormFieldProps}
                    label={
                      <div className="flex gap-3 items-center">
                        <Text color="text-black" $level={4} className={`text-left font-normal`}>
                          {`${tWAFForm('crossRequestMode')}`}
                        </Text>
                        <Responsive showBelow="sm">
                          <Button
                            hint={
                              <div className="bg-black -mx-2.5 -my-1.5 rounded-lg shadow-card px-2.5 py-1.5">
                                <Text
                                  color="text-white"
                                  $level={5}
                                  className="text-justify leading-5"
                                >{`${tWAFDescription('crossRequestHints')}`}</Text>
                              </div>
                            }
                            customizeColor
                            withoutScale
                            withoutPadding
                            icon={<InformationCircleIcon className="size-5 text-theme-primary-main" />}
                          />
                        </Responsive>
                      </div>
                    }
                    name="cross_request_mode"
                    inputType={InputType.Switch}
                    besideTitle={!isAboveSm}
                    disabled={!formik.values?.status || isSubmitting}
                    description={
                      <Responsive showAbove="sm">
                        <Text $level={5} className={`text-left font-normal leading-5`}>
                          {`${tWAFDescription('crossRequestHints')}`}
                        </Text>
                      </Responsive>
                    }
                  />
                  <FormField
                    {...defaultFormFieldProps}
                    label={
                      <div className="flex gap-3 items-center">
                        <Text color="text-black" $level={4} className={`text-left font-normal`}>
                          {`${tWAFForm('rulesDescription')}`}
                        </Text>
                        <Responsive showBelow="sm">
                          <Button
                            className="cursor-pointer"
                            withoutScale
                            hint={
                              <div className="bg-black -mx-2.5 -my-1.5 rounded-lg shadow-card px-2.5 py-1.5">
                                <Text
                                  color="text-white"
                                  $level={5}
                                  className="text-justify leading-5"
                                >{`${tWAFDescription('rules')}`}</Text>
                              </div>
                            }
                            customizeColor
                            withoutPadding
                            icon={<InformationCircleIcon className="size-5 text-theme-primary-main" />}
                          />
                        </Responsive>
                      </div>
                    }
                    className="flex gap-5 pt-8"
                    centerItem
                    name="rulesDescription"
                    inputType={InputType.Custom}
                    description={
                      <Responsive showAbove="sm">
                        <Text $level={5} className={`text-left font-normal leading-5`}>
                          {`${tWAFDescription('rules')}`}
                        </Text>
                      </Responsive>
                    }
                  >
                    <div className="flex flex-col gap-5 pt-5 w-full">
                      {formik.values.rulesDescription.map((action, index) => (
                        <div key={index} className="flex flex-col gap-2 text-us w-full">
                          <li className="font-medium text-xs sm:ml-2 text-gray-600">{`${action.name}`}</li>
                          <article className="font-light text-xs pl-4 sm:pl-6 text-gray-500 ">{`${action.description}`}</article>
                        </div>
                      ))}
                    </div>
                  </FormField>
                </div>
              </FormField>
            </div>
          </Cards>
        );
      }}
    </Formik>
  );
};

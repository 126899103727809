import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Translation } from '@enums';
import { PasswordValidationitem } from './passwordValidationItem';

export const ChangePasswordValidationDescription = ({ password }) => {
  const { setFieldValue } = useFormikContext();
  const { t: tForms } = useTranslation(Translation.ProfileSettings, { keyPrefix: 'forms' });
  const [passwordConditions, setPasswordConditions] = useState({
    minLength: false,
    noWhitespace: false,
  });

  useEffect(() => {
    const handlePasswordChange = () => {
      const conditions = {
        minLength: password.length >= 8,
        noWhitespace: password.length > 0 && /^\S*$/.test(password),
      };
      setPasswordConditions(conditions);
      setFieldValue('passwordConditions', conditions);
    };

    handlePasswordChange();
  }, [password, setFieldValue]);

  return (
    <article className="flex flex-col space-y-2">
      <PasswordValidationitem isMet={passwordConditions.minLength} message={tForms('eightCharacters')} />
      <PasswordValidationitem isMet={passwordConditions.noWhitespace} message={tForms('noWhiteSpace')} />
    </article>
  );
};

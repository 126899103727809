import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { getAllStorageData } from '@/storage';
import { Button } from '@components';
import { FormType, Translation, VariantType } from '@enums';
import { useAppDispatch } from '@hooks';
import { fetchFormList, useFormLoadingStatus } from '@store';
import { capitalize } from '@utils';

export const NavigationCategory = ({ categories, isCollapse }) => {
  const path = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formType = FormType.Cdn;

  const loading = useFormLoadingStatus({ formType });

  const { current_workspace_id: workspace_id } = getAllStorageData();

  const { t: tNavigation } = useTranslation(Translation.Common, { keyPrefix: 'navigator' });

  const handleClick = (navigationName, navigateLink) => {
    if (navigateLink === path.pathname && navigationName === 'cdn') {
      if (!loading) {
        dispatch(fetchFormList({ formType: FormType.Cdn, params: { workspace_id } }));
      }
    } else {
      navigate(navigateLink);
    }
  };

  return categories?.map((navigation) => (
    <nav key={navigation?.title} className="flex-col">
      {!isCollapse && !navigation?.subPath?.every((sub) => sub.hidden) && (
        <article className="font-medium text-gray-400 text-us">{capitalize(navigation?.title)}</article>
      )}
      <ul className={`flex-1 flex flex-col ${isCollapse ? 'gap-y-5' : 'gap-y-2'} pt-3`}>
        {navigation?.subPath?.map((item) => {
          const activeTab = path.pathname.split('/').slice(0, 2).join('/') === item.href;
          const dynamicButton = classNames('items-center rounded-xl ring-0 text-opacity-90', {
            'bg-slate-600 bg-opacity-10 text-gray-500': activeTab,
            'transition-all duration-200 ease-linear hover:text-gray-500 text-gray-300': !activeTab,
            'w-full px-2.5 py-2.5 space-x-3': !isCollapse,
            'w-fit px-2.5 py-2': isCollapse,
          });
          return (
            !item.hidden && (
              <Button
                variant={VariantType.Transparent}
                withoutCenter
                key={item.name}
                hintPlacement="left"
                hint={navigation?.subPath && <article className="text-sm">{tNavigation(item.name)}</article>}
                withoutPadding
                customizeColor
                iconWithoutM
                icon={<item.icon className="size-4 xl:size-5" aria-hidden="true" />}
                onClick={() => handleClick(item.name, item.href)}
                className={dynamicButton}
              >
                <article>{!isCollapse && tNavigation(item.name)}</article>
              </Button>
            )
          );
        })}
      </ul>
    </nav>
  ));
};

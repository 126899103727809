import { MinusCircleIcon } from '@heroicons/react/20/solid';
import { FieldArray, useFormikContext } from 'formik';
import { FC, Fragment, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Direction, FormField, Text } from '@components';
import { routeRulesConditionField } from '@constants';
import { ActionType, InputMod, Translation, VariantType, columnDirection, redirect_currentCustom } from '@enums';
import { validateValueEqual } from '@utils';

export const ConditionTableForm: FC<any> = memo(({ name, format, removeRow, row, rowId }) => {
  const { setFieldValue } = useFormikContext<any>();
  const { t: tForm } = useTranslation(Translation.Form, { keyPrefix: 'common' });
  const { t: tButton } = useTranslation(Translation.Common, { keyPrefix: 'button' });

  const conditionFields = useMemo(() => routeRulesConditionField(row.field) ?? [], [row.field]);

  useEffect(() => {
    conditionFields.forEach((fieldData) => {
      const fieldName = `${name}.[${rowId}].${fieldData.name}`;
      if (fieldData.name === 'values') {
        const initialValue = row?.values || fieldData.value;
        setFieldValue(fieldName, initialValue, false);
      } else if (fieldData.name === 'operator') {
        const initialValue = row?.operator || fieldData.value;
        setFieldValue(fieldName, initialValue, false);
      } else {
        setFieldValue(fieldName, fieldData.value, false);
      }
    });
  }, []);

  return (
    <FieldArray name={`${name}.[${rowId}]`}>
      {({ name: fieldName }) =>
        conditionFields.map((conditionForm, actionFormId) => {
          const key = `${fieldName}_${actionFormId}`;
          const isCurrentCustomRedirect =
            row.field === ActionType.redirect &&
            [
              validateValueEqual(conditionForm.name, 'uriValue') &&
                validateValueEqual(row.uri, redirect_currentCustom.Current),
              validateValueEqual(conditionForm.name, 'customUriArguments') &&
                validateValueEqual(row.uriArguments, redirect_currentCustom.Current),
              validateValueEqual(conditionForm.name, 'domain') &&
                validateValueEqual(row.host, redirect_currentCustom.Current),
            ].some((condition) => condition);

          if (isCurrentCustomRedirect) return null;
          return (
            <Fragment key={key}>
              {!validateValueEqual('values', conditionForm.name) &&
                !validateValueEqual('action', conditionForm.name) && (
                  <FormField
                    inputType={conditionForm.inputType}
                    label={format ? format(conditionForm.name) : conditionForm.name}
                    withoutLabel
                    options={conditionForm.option}
                    format={conditionForm.format}
                    placeholder={conditionForm.placeholder}
                    name={`${fieldName}.${conditionForm.name}`}
                    fullWidth
                    inputMod={InputMod.Filled}
                    smallFont
                    description={conditionForm.description}
                    type={conditionForm.type}
                    direction={columnDirection.Column}
                  />
                )}
              {validateValueEqual('values', conditionForm.name) &&
                row?.values?.map((_nestedItem, nestedIndex) => {
                  const nestedInputName = `${fieldName}.values.[${nestedIndex}].value`;

                  return (
                    <FieldArray key={nestedIndex} name={`${fieldName}.values`}>
                      {({ push, remove: removeValue }) => (
                        <>
                          <div className="flex items-start" key={nestedIndex}>
                            <FormField
                              inputType={conditionForm.inputType}
                              label={format ? format(conditionForm.name) : conditionForm.name}
                              withoutLabel
                              options={conditionForm.option}
                              format={conditionForm.format}
                              placeholder={conditionForm.placeholder}
                              hints={conditionForm.hints}
                              name={nestedInputName}
                              fullWidth
                              inputMod={InputMod.Filled}
                              smallFont
                              description={conditionForm.description}
                              adornmentWithoutM
                              type={conditionForm.type}
                              direction={columnDirection.Column}
                              adornmentPosition={Direction.Outside}
                              adornment={
                                <Button
                                  className={`self-center mr-2`}
                                  onClick={() => removeValue(nestedIndex)}
                                  variant={VariantType.Clean}
                                  withoutPadding
                                  disabled={row?.values.length <= 1}
                                  iconWithoutM
                                  icon={
                                    <MinusCircleIcon
                                      className={`w-6 h-6 ${row?.values.length <= 1 ? '' : 'text-red-500'} `}
                                    />
                                  }
                                />
                              }
                            />
                          </div>
                          {validateValueEqual(nestedIndex, row.values?.length - 1) && (
                            <div className="flex gap-3 py-1.5 justify-between">
                              <Button
                                variant={VariantType.Outlined}
                                className="p-3"
                                withoutPadding
                                onClick={() => removeRow(rowId)}
                              >
                                <Text $level={6} color="text-theme-primary-main" className="truncate max-w-fit">
                                  {tButton('deleteCondition')}
                                </Text>
                              </Button>
                              <Button
                                className=" bg-theme-primary-main text-white px-3 py-2.5"
                                withoutPadding
                                onClick={() => push({ value: conditionForm.emptyValue })}
                              >
                                <Text $level={6} color="text-white" className="truncate">
                                  {tForm('addMoreValue')}
                                </Text>
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </FieldArray>
                  );
                })}
            </Fragment>
          );
        })
      }
    </FieldArray>
  );
});

import i18n from 'i18next';

import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { call, delay, fork, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  addSnack,
  cancelAutoReload,
  deleteFormContent,
  deleteFormContentError,
  fetchDashboardGraph,
  fetchFormList,
  fetchFormListError,
  fetchFormListSuccess,
  fetchSortingListSuccess,
  resetSubmitFormState,
  submitFormError,
  submitFormRequest,
  submitFormSuccess,
  updateFormPages,
} from '@store';

import { universalFormConfig } from '@constants';
import { ApiEndpoint, DashboardEndpoint, FormType, StatusType } from '@enums';
import { API, createFormError, handleCallbackFunction, validateValueEqual } from '@utils';

import type { DashboardFilter, FormPayload } from '@types';

export function* fetchFormListSaga({ payload }: PayloadAction<FormPayload>) {
  const {
    formType,
    params,
    globalOptions: {
      pagination = false,
      content = false,
      endPath = false,
      filter = false,
      endpoint = ApiEndpoint.DEFAULT,
    } = {},
  } = payload;
  try {
    const { read } = universalFormConfig(formType);
    const { path, endPath: lastPath, mockResponse, formatData } = read[endpoint];

    let url = path;
    if (params?.content_id) {
      url += `/${params.content_id}`;
    }

    if (endPath && lastPath) {
      url += lastPath;
    }
    const { pagination: formPagination } = yield select((state) => state.form[formType]);
    const { filterCriteria } = yield select((state) => state.form[formType].filter);

    const getFiltersByEndpoint = (endpoint: string, filterCriteria: DashboardFilter[]) => {
      const sourceType = (() => {
        switch (endpoint) {
          case DashboardEndpoint.TRAFFIC_LOG:
            return DashboardEndpoint.TRAFFIC_LOG;
          case DashboardEndpoint.ERROR_LOG:
            return DashboardEndpoint.ERROR_LOG;
          default:
            return DashboardEndpoint.TOTAL_TRAFFIC;
        }
      })();

      return filterCriteria
        .filter((criteria) => criteria.from === sourceType)
        .reduce((acc, { label, value }) => {
          if (label === 'domain_id' && value === 'all') {
            return acc;
          }
          return {
            ...acc,
            [label]: value,
          };
        }, {});
    };

    const filterValue = getFiltersByEndpoint(endpoint, filterCriteria);

    const { page, page_size } = formPagination;
    const requestParams = {
      ...(params && {
        ...Object.fromEntries(Object.entries(params).filter(([key]) => key !== 'content_id')),
      }),
      ...(filter && { ...filterValue }),
      ...(pagination && { page, page_size }),
    };
    const getCurrentUnixTime = () => {
      return Math.floor(Date.now() / 1000);
    };

    if (
      validateValueEqual(formType, FormType.Workspace) ||
      validateValueEqual(formType, FormType.WorkspaceConfiguration)
    ) {
      yield put(
        fetchFormListSuccess({
          formType,
          endpoint,
          lastUpdated: getCurrentUnixTime(),
          list: mockResponse,
          ...(pagination && {
            paginationInfo: {
              total: mockResponse.length ?? 0,
              total_pages: 1,
              first_page: 1,
              last_page: 1,
              next_page: 1,
              page: 1,
              page_size: 10,
            },
          }),
        }),
      );
      return;
    }

    const { data, headers } = yield call(API.get, url, requestParams ? { params: requestParams } : undefined);

    const paginationHeader = headers['x-pagination'] || headers['X-Pagination'];
    const paginationInfo = paginationHeader ? JSON.parse(paginationHeader) : null;

    if (validateValueEqual(endpoint, ApiEndpoint.SORTING) && data && Array.isArray(data)) {
      const updatedResponse = data.map((item, index) => ({
        ...item,
        original_id: index + 1,
        sort_order: index,
      }));

      yield put(
        fetchSortingListSuccess({
          formType,
          endpoint,
          responseResult: updatedResponse,
        }),
      );
      return;
    }

    const formatedData = formatData ? yield call(formatData, data) : data;

    yield put(
      fetchFormListSuccess({
        formType,
        endpoint,
        content,
        lastUpdated: getCurrentUnixTime(),
        list: formatedData,
        ...(pagination && {
          paginationInfo,
        }),
      }),
    );
  } catch (error) {
    if (!axios.isCancel(error)) {
      yield put(
        fetchFormListError({
          formType,
          endpoint,
          responseResult: createFormError(error),
        }),
      );
    }
  }
}

export function* fetchDashboardGraphSaga({ payload }: PayloadAction<any>) {
  const { globalOptions } = payload;
  if (!globalOptions?.endpoint?.length) return;
  const autoReload = globalOptions?.reload ?? false;

  try {
    while (true) {
      const createBatchPayloads = (payload: any): any[] =>
        payload.globalOptions.endpoint.map((endpoint) => ({
          formType: payload.formType,
          params: payload.params,
          globalOptions: {
            ...payload.globalOptions,
            endpoint,
          },
        }));

      const batchPayloads = createBatchPayloads(payload);

      for (const batchPayload of batchPayloads) {
        yield fork(function* () {
          yield put(fetchFormList(batchPayload));
        });
      }

      if (!autoReload) break;

      const { cancel } = yield race({
        timeout: delay(10000),
        cancel: take(cancelAutoReload.type),
      });
      if (cancel) {
        break;
      }
    }
  } catch (error) {
    console.warn(error);
  }
}

export function* submitFormRequestSaga({ payload }: PayloadAction<FormPayload>) {
  const {
    formType,
    formData,
    params,
    globalOptions: {
      pagination = false,
      refetch_id,
      response = false,
      returnResult = false,
      toast = true,
      refetchAnywhere = false,
      content = false,
      endpoint = ApiEndpoint.DEFAULT,
    } = {},
    callbackFunction,
  } = payload;
  try {
    const { procedure } = yield select((state) => state.form[formType]);

    const { write } = universalFormConfig(formType);

    const { path, translation } = write[endpoint];

    let url = path;
    if (params?.content_id) {
      url += `/${params.content_id}`;
    }

    const requestParams = params?.content_id ? undefined : { params };
    const { data, headers } = yield call(API.post, url.toString(), formData, requestParams);

    const paginationHeader = headers['x-pagination'] || headers['X-Pagination'];
    const paginationInfo = paginationHeader ? JSON.parse(paginationHeader) : null;

    if (toast) {
      yield put(
        addSnack({ type: StatusType.Success, message: i18n.t(`${translation}.${procedure.toLowerCase()}Successful`) }),
      );
    }
    if (response) {
      yield put(
        submitFormSuccess({
          formType,
          endpoint,
          content: data,
        }),
      );
    } else {
      yield put(
        submitFormSuccess({
          formType,
          endpoint,
        }),
      );
    }

    if (!refetchAnywhere && callbackFunction?.refetch) {
      yield* handleCallbackFunction(callbackFunction.refetch);
    }

    if (refetch_id) {
      yield put(
        fetchFormList({
          formType,
          params: { application_id: refetch_id },
          globalOptions: { pagination: true },
        }),
      );
    }

    if (returnResult) {
      const getCurrentUnixTime = () => {
        return Math.floor(Date.now() / 1000);
      };
      yield put(
        fetchFormListSuccess({
          formType,
          endpoint,
          lastUpdated: getCurrentUnixTime(),
          content: content,
          list: data,
          ...(pagination && { paginationInfo }),
        }),
      );
    }
  } catch (error) {
    const axiosError = error as AxiosError<{ data: any }>;
    if (axiosError.response) {
      const { data } = axiosError.response.data;
      yield put(submitFormError({ formType, endpoint, responseResult: data }));
    }
    if (refetchAnywhere && callbackFunction?.refetch) {
      yield* handleCallbackFunction(callbackFunction.refetch);
      if (refetch_id) {
        yield put(resetSubmitFormState(formType));
        yield put(
          fetchFormList({
            formType,
            params: { application_id: refetch_id },
            globalOptions: { pagination: true },
          }),
        );
      }
    }
  }
}

export function* deleteFormContentSaga({ payload }: PayloadAction<FormPayload>) {
  const {
    formType,
    params,
    globalOptions: { toast = true, refetch_id, refetchAnywhere = false, endpoint = ApiEndpoint.DEFAULT } = {},
    callbackFunction,
  } = payload;
  const { write } = universalFormConfig(formType);

  try {
    const { path, translation } = write[endpoint];

    const { procedure } = yield select((state) => state.form[formType]);
    const { pagination } = yield select((state) => state.form[formType]);

    const deletedCount = 1;
    let url = path;
    if (params?.content_id) {
      url += `/${params.content_id}`;
    }

    const { content_id, ...updatedParams } = params as any;

    yield call(API.delete, url);

    const updatedPage = yield call(
      getUpdatedPage,
      pagination.page,
      deletedCount,
      pagination.total,
      pagination.page_size,
    );

    if (toast) {
      yield put(
        addSnack({ type: StatusType.Success, message: i18n.t(`${translation}.${procedure.toLowerCase()}Successful`) }),
      );
    }

    if (!refetchAnywhere && callbackFunction?.refetch) {
      yield* handleCallbackFunction(callbackFunction.refetch);
    }

    if (updatedPage !== pagination.page) {
      yield put(
        updateFormPages({ formType, params: { application_id: refetch_id, ...updatedParams }, page: updatedPage }),
      );
    } else if (refetch_id) {
      yield put(resetSubmitFormState(formType));
      yield put(
        fetchFormList({
          formType,
          params: { application_id: refetch_id },
          globalOptions: { pagination: true },
        }),
      );
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorDetails = errorResponse?.response?.data ?? error;
    yield put(deleteFormContentError({ formType, endpoint, error: errorDetails }));
    if (refetchAnywhere && callbackFunction?.refetch) {
      yield* handleCallbackFunction(callbackFunction.refetch);
      if (refetch_id) {
        yield put(resetSubmitFormState(formType));
        yield put(
          fetchFormList({
            formType,
            params: { application_id: refetch_id },
            globalOptions: { pagination: true },
          }),
        );
      }
    }
  }
}

export function* updateFormPagesSaga({ payload }: any) {
  const { formType, params } = payload;
  yield put(
    fetchFormList({
      formType,
      params,
      globalOptions: {
        pagination: true,
      },
    }),
  );
}

function getUpdatedPage(currentPage: number, deletedCount: number, total: number, pageSize: number) {
  const newTotal = total - deletedCount;
  const newTotalPages = Math.ceil(newTotal / pageSize);
  return Math.max(1, Math.min(currentPage, newTotalPages));
}

export function* formSagaWatcher() {
  yield takeEvery(fetchFormList.type, fetchFormListSaga);
  yield takeLatest(fetchDashboardGraph.type, fetchDashboardGraphSaga);
  yield takeLatest(submitFormRequest.type, submitFormRequestSaga);
  yield takeLatest(deleteFormContent.type, deleteFormContentSaga);
  yield takeLatest(updateFormPages.type, updateFormPagesSaga);
}

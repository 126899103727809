import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { workSpaceId } from '@/storage';
import {
  getWorkspaceOptions,
  getWorkspaceOptionsError,
  getWorkspaceOptionsSuccess,
  updateWorkspaceId,
  updateWorkspaceIdError,
  updateWorkspaceIdSuccess,
} from '@store';
import { API } from '@utils';

export function* getWorkspaceOptionSaga() {
  try {
    const { data: responseData } = yield call(API.get, '/workspace');
    const { workspace_list, default_workspace_id } = responseData;
    const current_workspace_id = workSpaceId.getItem();

    if (!current_workspace_id || current_workspace_id !== default_workspace_id) {
      yield call(updateWorkspaceIdSaga, { type: getWorkspaceOptions.type, payload: default_workspace_id });
    } else yield put(updateWorkspaceIdSuccess({ workspace_id: Number(current_workspace_id) }));

    yield put(getWorkspaceOptionsSuccess(workspace_list));
  } catch (error: any) {
    yield put(getWorkspaceOptionsError(error));
  }
}

export function* updateWorkspaceIdSaga({ payload: workspace_id }: PayloadAction<number | string>) {
  try {
    const currentWorkspaceId = workSpaceId.getItem();
    if (workspace_id && workspace_id !== currentWorkspaceId) {
      yield call(workSpaceId.setItem, String(workspace_id));
    }
    yield put(updateWorkspaceIdSuccess({ workspace_id: Number(workspace_id) }));
  } catch (error: any) {
    yield put(updateWorkspaceIdError(error));
  }
}

export function* workspaceSagaWatcher() {
  yield takeLatest(getWorkspaceOptions.type, getWorkspaceOptionSaga);
  yield takeLatest(updateWorkspaceId.type, updateWorkspaceIdSaga);
}

import { Formik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormField, FormModal } from '@components';
import { changePasswordSchema } from '@constants';
import { InputMod, Translation } from '@enums';
import { useAppDispatch } from '@hooks';
import {
  resetProfileSettings,
  submitChangePasswordForm,
  useProfileSettingsError,
  useProfileSettingsSaveSuccess,
  useProfileSettingsSubmitting,
} from '@store';
import { ChangePasswordValidationDescription } from '../subComponent';

interface ChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
}

export const ChangePasswordModal = ({ open, onClose }: ChangePasswordModalProps) => {
  const dispatch = useAppDispatch();
  const { t: tForms } = useTranslation(Translation.ProfileSettings, { keyPrefix: 'forms' });
  const { t: tModals } = useTranslation(Translation.ProfileSettings, { keyPrefix: 'modals' });
  const { t: tCommonButton } = useTranslation(Translation.Common, { keyPrefix: 'button' });

  const submitError = useProfileSettingsError();
  const isSubmitting = useProfileSettingsSubmitting();
  const saveSuccess = useProfileSettingsSaveSuccess();

  const initialValues = {
    current_password: '',
    password: '',
    confirm_password: '',
  };

  const handleSubmit = (values) => {
    const { current_password, password, confirm_password } = values;
    dispatch(
      submitChangePasswordForm({
        current_password,
        password,
        confirm_password,
      }),
    );
  };

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      onClose();
      dispatch(resetProfileSettings());
    }
  }, [dispatch, isSubmitting, onClose, saveSuccess, submitError]);

  return (
    <Formik initialValues={initialValues} validationSchema={changePasswordSchema} onSubmit={handleSubmit}>
      {({ handleChange, handleBlur, values }) => {
        return (
          <FormModal
            title={tModals('changeYourPassword')}
            onClose={onClose}
            submitError={submitError}
            open={open}
            loading={isSubmitting}
            confirmButton={{ children: tCommonButton('changePassword') }}
          >
            <FormField
              label={tForms('currentPassword')}
              name="current_password"
              withoutLabel
              type="password"
              loading={isSubmitting}
              smallFont
              inputMod={InputMod.Filled}
              placeholder={tForms('enterYourCurrentPassword')}
              fullWidth
            />
            <div className="space-y-6 text-xs">
              <FormField
                label={tForms('newPassword')}
                name="password"
                withoutLabel
                loading={isSubmitting}
                smallFont
                type="password"
                inputMod={InputMod.Filled}
                placeholder={tForms('enterYourNewPassword')}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              <ChangePasswordValidationDescription password={values.password} />
            </div>
            <FormField
              label={tForms('confirmPassword')}
              name="confirm_password"
              withoutLabel
              type="password"
              loading={isSubmitting}
              smallFont
              inputMod={InputMod.Filled}
              placeholder={tForms('enterYourConfirmPassword')}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirm_password}
            />
          </FormModal>
        );
      }}
    </Formik>
  );
};

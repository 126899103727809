import { Globe2, House, Settings } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '@constants';
import { Translation } from '@enums';
import { useCurrentWorkspaceId } from '@store';
import { useRoutePermissions } from '../system';

export const useSidebarNavigation = () => {
  const { showRoute } = useRoutePermissions();
  const { pathname } = useLocation();

  const { t: tCommon } = useTranslation(Translation.Common, { keyPrefix: 'navigator' });

  const currentWorkspaceId = useCurrentWorkspaceId();

  const navigationData = useMemo(
    () => [
      {
        title: tCommon('navigation'),
        subPath: [
          {
            name: 'homepage',
            href: ROUTES.homepage,
            icon: House,
            current: false,
            hidden: !showRoute.homepage,
          },
          {
            name: 'cdn',
            href: ROUTES.cdn,
            icon: Globe2,
            current: false,
            hidden: !showRoute.cdn || !currentWorkspaceId,
            notification: 5,
          },
        ],
      },
      {
        title: tCommon('account'),
        subPath: [
          {
            name: 'profile_settings',
            href: ROUTES.profileSettings,
            icon: Settings,
            current: false,
            hidden: !showRoute.profileSettings,
          },
        ],
      },
    ],
    [tCommon, showRoute, currentWorkspaceId],
  );

  const getNavigationData = useMemo(() => {
    return navigationData.map((category) => ({
      ...category,
      subPath: category.subPath.map((item) => ({
        ...item,
        current: pathname.startsWith(item.href),
      })),
    }));
  }, [navigationData, pathname]);

  return getNavigationData;
};

import { call, put } from 'redux-saga/effects';

export function* handleCallbackFunction(callback: any) {
  if (!callback) {
    return;
  }

  if (typeof callback === 'function') {
    yield call(callback);
  } else if (typeof callback === 'object' && callback.type) {
    yield put(callback);
  }
}

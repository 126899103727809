import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from '@hooks';

interface Workspace {
  id: number;
  name: string;
}

interface WorkspaceOptionsState {
  isLoading: boolean;
  options: Workspace[];
  error: Error[] | Error | null | string;
  currentId: number | null;
}

const initialState: WorkspaceOptionsState = {
  isLoading: false,
  options: [],
  error: null,
  currentId: null,
};

const workspaceOptionsSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    getWorkspaceOptions: (state) => {
      state.isLoading = true;
    },
    getWorkspaceOptionsSuccess: (state, { payload }: PayloadAction<Workspace[]>) => {
      state.options = payload;
      state.isLoading = false;
    },
    getWorkspaceOptionsError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isLoading = false;
    },
    updateWorkspaceId: (state, { payload }: PayloadAction<number>) => {
      state.currentId = payload;
    },
    updateWorkspaceIdSuccess: (state, { payload }: PayloadAction<{ workspace_id: number }>) => {
      state.currentId = payload.workspace_id;
      state.isLoading = false;
    },
    updateWorkspaceIdError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearWorkspaceOptions: () => initialState,
  },
});

export const {
  getWorkspaceOptions,
  getWorkspaceOptionsSuccess,
  getWorkspaceOptionsError,
  updateWorkspaceId,
  updateWorkspaceIdSuccess,
  updateWorkspaceIdError,
  clearWorkspaceOptions,
} = workspaceOptionsSlice.actions;

export const useWorkspaceOptionsState = () => useAppSelector((state: RootState) => state.workspace);
export const useWorkspaceOptions = () => useAppSelector((state: RootState) => state.workspace.options);
export const useWorkspaceOptionsLoading = () => useAppSelector((state: RootState) => state.workspace.isLoading);
export const useWorkspaceOptionsError = () => useAppSelector((state: RootState) => state.workspace.error);
export const useCurrentWorkspaceId = () => useAppSelector((state: RootState) => state.workspace.currentId);

export default workspaceOptionsSlice.reducer;
